import React from 'react';
import PageWrapper from './pagecontent.style';

const PageContent = ({ html }) => {
  return (
    <PageWrapper>
      <div className="page-body" dangerouslySetInnerHTML={{ __html: html }} />
    </PageWrapper>
  );
};

export default PageContent;
