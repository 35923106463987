import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/SolarNavbar';
import Banner from 'common/source/containers/Banner';
// import BlogSection from 'common/source/containers/BlogSection';
// import TimelineSection from 'common/source/containers/TimelineSection';
// import ContactSections from 'common/source/containers/Contact';
// import PartnerSection from 'common/source/containers/Partner';
// import FeatureSection from 'common/source/containers/FeatureSection';
import Footer from 'common/source/containers/Footer';
import Container from 'common/source/components/UI/Container';

import SEO from '../components/SEO';
import PageContent from '../components/PageContent';
import config from '../../data/SiteConfig';

class WarrantyPage extends React.Component {
  render() {
    const { data } = this.props;
    const { page } = data;
    const { html, frontmatter } = page;
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO
            pageTitle={`${frontmatter.title} | ${config.siteTitle}`}
            path={`/${frontmatter.slug}`}
          />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar />
            <Banner heading={frontmatter.title} />
            <Container>
              <PageContent html={html} />
            </Container>
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}
export default WarrantyPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query WarrantyQuery {
    page: markdownRemark(frontmatter: { slug: { eq: "politicas-de-garantia" } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
      }
      frontmatter {
        slug
        title
        subtitle
        description
        type
        cover
        thumbnail
      }
    }
  }
`;
